import React from 'react'
import { Grid, Card, Image, Header } from 'semantic-ui-react'

import Layout from '../components/layout'
import SEO from '../components/seo'

const AboutPage = () => (
  <Layout>
    <SEO title="About the Author" />


    <Grid columns={2} centered verticalAlign="middle">

      <Grid.Row>

        <Grid.Column width={4}>
          <Image src="../../pedroni.jpg" width="200" wrapped />
        </Grid.Column>

        <Grid.Column width={12}>
          <Header>Prof. Volnei A. Pedroni</Header>

          <p>The author received his MSc and PhD degrees in Electrical Engineering from the California Institute of Technology (Caltech), with emphasis on VLSI design, analog and digital. He is a Professor of Electronics Engineering at Brazil's UTFPR and a regular Visiting Faculty of Electrical Engineering at Caltech. He has done collaboration also with other Institutions, in USA and Italy (he is a Brazilian and Italian citizen).</p>

          <p>
            Contact:<br />
                pedroni@utfpr.edu.br<br />
                vpedroni@caltech.edu
              </p>
        </Grid.Column>

      </Grid.Row>

    </Grid>


  </Layout>
)

export default AboutPage
